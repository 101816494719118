import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Grid,
  Thumbs,
} from 'swiper/modules'
import { media } from './functions/media'
import { checkQuizSlide } from './quiz'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

export const createProjectSlider = (): void => {
  const projectBullets = new window.Swiper('.project-bullets .swiper', {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 12,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.md]: {
        slidesPerView: 4,
      },
    },
  }) as Swiper

  new window.Swiper('.project-slider .swiper', {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    grabCursor: true,
    watchSlidesProgress: true,
    thumbs: {
      swiper: projectBullets,
    },
  }) as Swiper
}

export default (): void => {
  new window.Swiper('.projects-slider .swiper', {
    pagination: {
      el: '.projects-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.projects-slider .swiper-button-prev',
      nextEl: '.projects-slider .swiper-button-next',
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    loop: true,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
      },
      [media.md]: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      [media.lg]: {
        slidesPerView: 2.7,
      },
      [media.xl]: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper

  new window.Swiper('.solutions-slider .swiper', {
    pagination: {
      el: '.solutions-slider .swiper-pagination',
      clickable: true,
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    grabCursor: true,
    watchSlidesProgress: true,
    allowTouchMove: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.7,
      },
      [media.md]: {
        slidesPerView: 2,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 28,
      },
    },
  }) as Swiper

  new window.Swiper('.quiz-slider .swiper', {
    navigation: {
      prevEl: '.quiz-slider .swiper-button-prev',
      nextEl: '.quiz-slider .swiper-button-next',
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 30,
    allowTouchMove: false,
    watchSlidesProgress: true,
    on: {
      slideChange: (swiper: Swiper): void => {
        checkQuizSlide(swiper.visibleSlides[0])

        switch (
          swiper.visibleSlides[0] === swiper.slides[swiper.slides.length - 1]
        ) {
          case true: {
            ;(swiper.el.closest('[data-quiz]') as HTMLElement).setAttribute(
              'data-quiz-end',
              ''
            )
            break
          }

          case false: {
            ;(swiper.el.closest('[data-quiz]') as HTMLElement).removeAttribute(
              'data-quiz-end'
            )
            break
          }
        }
      },
    },
  }) as Swiper

  new window.Swiper('.clients-slider .swiper', {
    pagination: {
      el: '.clients-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.clients-slider .swiper-button-prev',
      nextEl: '.clients-slider .swiper-button-next',
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    loop: true,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.3,
      },
      [media.lg]: {
        slidesPerView: 1.5,
        spaceBetween: 28,
      },
      [media.xl]: {
        slidesPerView: 2,
      },
    },
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper

  new window.Swiper('.cooperation-slider .swiper', {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    spaceBetween: 20,
    grabCursor: true,
    loop: true,
    watchSlidesProgress: true,
    allowTouchMove: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 2,
      },
      [media.md]: {
        slidesPerView: 3,
      },
      [media.lg]: {
        slidesPerView: 4,
      },
      [media.xl]: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        allowTouchMove: false,
        loop: false,
      },
    },
  }) as Swiper

  new window.Swiper('.reviews-slider .swiper', {
    pagination: {
      el: '.reviews-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.reviews-slider .swiper-button-prev',
      nextEl: '.reviews-slider .swiper-button-next',
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    grabCursor: true,
    loop: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
      },
      [media.md]: {
        slidesPerView: 2,
      },
      [media.lg]: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      [media.xl]: {
        slidesPerView: 3,
      },
    },
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper
}

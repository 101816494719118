import fancybox from './ts/fancybox'
import sliderSwiper from './ts/slider-swiper'
import yandexMap from './ts/yandex-map'
import scrollHeader from './ts/scroll-header'
import currentTab from './ts/current-tab'
import currentYear from './ts/current-year'
import sidebar from './ts/sidebar'
import scrollTo from './ts/scroll-to'
import animation from './ts/animation'
import waved from './ts/waved'
import accordion from './ts/accordion'
import parallax from './ts/parallax'
import submitHandler from './ts/submit-handler'
import inputs from './ts/inputs'
import phoneMask from './ts/phone-mask'
import quiz from './ts/quiz'
import preloader from './ts/preloader'

import './scss/style.scss'

const initialization = (): void => {
  fancybox()
  sliderSwiper()
  yandexMap()
  currentTab()
  currentYear()
  scrollHeader()
  sidebar()
  scrollTo()
  animation()
  waved()
  accordion()
  parallax()
  submitHandler()
  inputs()
  phoneMask()
  quiz()
  preloader()
}

window.addEventListener('DOMContentLoaded', initialization as EventListener)
